$(document).ready(() => {
    const pricingToggle = $('#pricing-toggle');
    const pricingMonthly = $('#pricing-monthly-option');
    const pricingYearly = $('#pricing-yearly-option');

    const updatePricing = (monthly) => {
        let monthlyPrice = $('.price.price-monthly');
        let yearlyPrice = $('.price.price-yearly');
        let discountBadge = $('.badge-discount');

        if (monthly) {
            monthlyPrice.show();
            yearlyPrice.hide();
            discountBadge.hide();
            pricingMonthly.addClass('active');
            pricingYearly.removeClass('active');
        } else {
            monthlyPrice.hide();
            yearlyPrice.show();
            discountBadge.show();
            pricingYearly.addClass('active');
            pricingMonthly.removeClass('active');
        }
    }

    pricingMonthly.click((e) => {
        updatePricing(true);
        pricingToggle.prop('checked', true);
    })

    pricingYearly.click((e) => {
        updatePricing(false);
        pricingToggle.prop('checked', false);
    })

    pricingToggle.change((e) => {
        let monthly = e.target.checked;
        updatePricing(monthly);
    })
});