// ------ JQuery ------
global.$ = global.jQuery = $;

import './css/main.scss';

import './libs/eNno/assets/vendor/icofont/icofont.min.css';
import './libs/eNno/assets/vendor/boxicons/css/boxicons.min.css';

import './libs/eNno/assets/vendor/bootstrap/js/bootstrap.bundle.min.js';

import './libs/eNno/assets/vendor/jquery.easing/jquery.easing.min.js';
// import './libs/eNno/assets/vendor/php-email-form/validate.js';
import './libs/eNno/assets/vendor/waypoints/jquery.waypoints.min.js';

import { counterUp } from './libs/eNno/assets/vendor/counterup/counterup.min.js';
global.$.counterUp = counterUp;


var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
// make Isotope a jQuery plugin
jQueryBridget( 'isotope', Isotope, $ );

import './libs/eNno/assets/vendor/venobox/venobox.min.js';
import './libs/eNno/assets/vendor/venobox/venobox.css';

import './libs/eNno/assets/vendor/owl.carousel/owl.carousel.min.js';
import './libs/eNno/assets/vendor/owl.carousel/assets/owl.carousel.min.css';

import "magnific-popup/dist/jquery.magnific-popup";
import "magnific-popup/dist/magnific-popup.css";

import "./js/magnific-popup";

import "./js/pricing";

import './libs/eNno/assets/js/main';

import 'flag-icon-css/css/flag-icon.css';
