$(function () {
    $('.page-content').magnificPopup({
        delegate: 'a[href$=".jpg"]:has(img), a[href$=".png"]:has(img), a[href$=".jpeg"]:has(img)',
        type: 'image',
        closeOnContentClick: false,
        closeBtnInside: true,
        mainClass: 'mfp-with-zoom mfp-img-mobile',
        image: {
            verticalFit: true,
            titleSrc: function (item) {
                return item.el.attr('title');
            },
        },
        gallery: {
            enabled: true,
            tCounter: '%curr% z %total%'
        },
        zoom: {
            enabled: true,
            duration: 300,
            opener: function (element) {
                return element.find('img');
            },
        },
    });

    $('.popup-map').magnificPopup({
        disableOn: 600,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });
});
